import { FC } from "react";
import "./nav.scss";

interface Props {
  navigation: {
    label: string;
    url: string;
  }[];
}

export const Nav: FC<Props> = ({ navigation }) => {
  return (
    <nav className="nav">
      {navigation.map((navItem, i) => {
        return (
          <a
            key={i}
            className="nav__item"
            href={navItem.url}
            target="_blank"
            rel="noreferrer"
          >
            {navItem.label}
          </a>
        );
      })}
    </nav>
  );
};
