export const navigation = [
  {
    url: "https://instagram.com/kenmallar",
    label: "Instagram",
  },
  {
    url: "https://facebook.com/kenmallar",
    label: "Facebook",
  },
  {
    url: "https://www.youtube.com/channel/UCoG-SeDHeBs7QZeJz6RWeJA",
    label: "Youtube",
  },
  {
    url: "mailto:salut@kenmallar.com",
    label: "Contact",
  },
];
