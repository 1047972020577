export const perks = ["Illustrateur", "BEdEiste", "Dev Front-End"];

export const musicData = {
  personne: [
    "https://personne.bandcamp.com/album/schlaben-die-kartoffel",
    "https://personne.bandcamp.com/album/haters-gonna-hate",
  ],
  talbot: [
    "https://noetalbot.bandcamp.com/album/laisser-le-poste-ouvert",
    "https://noetalbot.bandcamp.com/album/d-baller-le-pr-sent",
  ],
  gxp: ["https://guerillapoubelle.bandcamp.com/album/c-tait-mieux-avant"],
};
