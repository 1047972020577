import { FC } from "react";
import { perks, navigation } from "../../data";
import { Background } from "../background";
import { Nav } from "../nav";
import "./app.scss";

export const App: FC = () => {
  return (
    <>
      <div className="app">
        <h1 className="app__title">KEN MALLAR</h1>
        <h3 className="app__subtitle">
          {perks.map((perk, i) => (
            <div key={i}>{perk}</div>
          ))}
        </h3>
        <Nav navigation={navigation} />
        <Background />
      </div>
    </>
  );
};
